/* import montserrat */
@font-face {
  font-family: Montserrat;
  font-weight: 500;
  src: url("./fonts/Montserrat-Medium.ttf");
}

@font-face {
  font-family: Montserrat;
  font-weight: semi-bold;
  src: url("./fonts/Montserrat-SemiBold.ttf");
}

@font-face {
  font-family: Montserrat;
  font-weight: bold;
  src: url("./fonts/Montserrat-Bold.ttf");
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0px;
}

/* css elements generics (not aggrid) */
/* sidebar decrease z-index to avoid it being on top of modals */
.MuiDrawer-paper {
  z-index: 1 !important;
}

/* checkbox (first column) table Your Cases */
.checkboxYourCases label {
  cursor: pointer !important;
}

/* react-numeric-input (used in fifth step of creation) */
.react-numeric-input input {
  border-color: transparent transparent #7c7c7c transparent !important;
  outline: none !important;
}

.react-numeric-input b {
  background: transparent !important;
  border: 0px !important;
  box-shadow: none !important;
}

/* plotly notifier (popup on click modebar) */
.plotly-notifier {
  max-width: 400px !important;
  bottom: 0 !important;
  top: auto !important;
  left: 45% !important;
}

/* aggrid tables style */
.ag-filter-apply-panel-button {
  color: #2569cf;
  border-color: #5997f0;
  border-radius: 5px;
  background-color: white;
}

.ag-filter-apply-panel-button:hover {
  background-color: #5997f0;
  color: white;
  border-color: #5997f0;
}

.ag-header-cell {
  padding-left: 6px !important;
}

.ag-cell {
  line-height: 20px !important;
  padding-left: 6px !important;
}

.ag-row {
  height: 22px !important;
}

.ag-row-hover {
  background-color: rgba(120, 174, 255, 0.3) !important;
  cursor: pointer;
}

.ag-group-expanded,
.ag-group-contracted {
  height: 100% !important;
}

/* specific ag-grid classes */
.centerHeader .ag-header-cell-label {
  justify-content: center !important;
}

.bigAgGridHeader {
  font-size: 20px !important;
}

.normalAgGridHeader {
  font-size: 14px !important;
}

/* initial loading spinner */
.spinner {
  animation: spin infinite 2s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* excel style ag-grid */
.excel-style.ag-theme-balham .ag-row {
  background-color: white !important;
}

.excel-style.ag-theme-balham .ag-row:hover {
  background-color: #f7f7f7 !important;
}

.excel-style .ag-header-cell,
.ag-header-group-cell {
  border-right: 1px solid #d9d9d9 !important;
}

.excel-style .ag-header-cell .darker-border,
.ag-header-group-cell {
  border-right: 1px solid #bbbbbb !important;
}

.excel-style .ag-cell {
  border-right-color: #d9d9d9 !important;
  border-right-width: 1px !important;
  border-right-style: solid !important;
}

.excel-style .ag-cell.darker-border {
  border-right-color: #bbbbbb !important;
}

.excel-style .ag-header-cell.darker-border {
  border-right-color: #bbbbbb !important;
}

.excel-style.ag-theme-balham .ag-header {
  background-color: white !important;
}

.excel-style .ag-header-cell-resize {
  background-color: white !important;
  right: 0px !important;
}

.excel-style .ag-cell-focus.darker-border,
.excel-style .ag-cell-focus {
  border-color: #0091ea !important;
}

.excel-style .ag-header-group-cell,
.excel-style .ag-header-cell {
  font-family: Montserrat !important;
  font-weight: bold !important;
  color: black !important;
}

.excel-style .ag-row {
  font-family: Montserrat !important;
  font-weight: 500 !important;
  color: black !important;
}

.excel-style .ag-header-cell-label,
.excel-style .ag-header-group-cell-label {
  justify-content: center !important;
}

.excel-style .ag-header-group-cell-label {
  font-size: 14px !important;
}

.excel-style .ag-header-cell-label {
  font-size: 14px !important;
}

.no-border-bottom.ag-theme-balham .ag-row {
  border-bottom: 0px !important;
}

.excel-style .ag-cell.totals {
  background-color: rgba(227, 227, 227, 0.5) !important;
  font-weight: bold !important;
}

.excel-style .ag-cell-range-selected.totals {
  background-color: #cee9fb !important;
}

.excel-style .ag-cell-highlight.totals {
  background-color: #0091ea !important;
  transition: background-color 0ms ease !important;
}

.excel-style .ag-header-cell.total_header .ag-header-cell-resize,
.excel-style .ag-header-cell.total_header {
  background-color: #e9e9e9 !important;
}

/* prevent aggregated data from being opened or closed*/
.info.legend {
  background-color: white;
}

.dashboardSelect .MuiOutlinedInput-input {
  color: black !important;
}

.routeLink {
  float: left;
  width: 25%;
  height: 80%;
  padding: 1.5rem;
  padding-left: 5rem;
  color: #6c757d;
}

.sankey-link:hover {
  /* fill: #6c757d !important; */
  fill-opacity: 1 !important;
}

/* modal text of SuccessAlert() InfoAlert() and ErrorAlert() */
.swal2-title {
  font-size: 22px !important;
}

.nav-link:not(active) {
  cursor: pointer;
}

.bold-row {
  font-weight: bold;
}

.page {
  width: 21cm;
  max-height: 19.7cm;
  padding: 0.2cm;
  margin: 0.5cm auto;
  padding-top: 0;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: "white";
  overflow-y: scroll;
}

.subpage {
  height: 282mm;
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  .page {
    min-height: 29.7cm;
    max-height: none;
    overflow-y: hidden;
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }

  .hideOnPrint {
    display: none;
  }
}

.ag-tooltip {
  background-color: #ffffff !important;
}

.light-blue-background {
  min-height: 100vh;
  background-color: #d2eaff;
}

.light-blue-box {
  height: 100%;
  background-color: #d2eaff;
  border-radius: 7px;
  padding: 10;
  box-shadow: 0px 1px 2px #939393;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-align: center;
  color: black;
}

.light-blue-box:hover {
  text-decoration: underline;
}

.textUnderlineOnHover:hover {
  text-decoration: underline;
}

.contentToFadeIn {
  -webkit-animation: contentToFadeIn linear 1s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: contentToFadeIn linear 1s;
  /* Firefox < 16 */
  -ms-animation: contentToFadeIn linear 1s;
  /* Internet Explorer */
  -o-animation: contentToFadeIn linear 1s;
  /* Opera < 12.1 */
  animation: contentToFadeIn linear 1s;
}

@keyframes contentToFadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

/* custom scrollbar */
/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* when there is custom scrollbar write the following to prevent the "double scrollbar" bug in aggrid */
.ag-center-cols-viewport::-webkit-scrollbar {
  height: 0px;
}

.noPaddingCheckBox.MuiButtonBase-root {
  padding: 0px !important;
}

/* arrows left and right in create policy */
.arrow-1 {
  width: 100px;
  height: 30px;
  display: flex;
}

.arrow-1:before {
  content: "";
  background: currentColor;
  width: 100%;
  clip-path: polygon(0 43%,
      75% 43%,
      75% 24%,
      100% 50%,
      75% 77%,
      75% 59%,
      0 59%);
}

.arrow-2 {
  width: 100px;
  height: 30px;
  display: flex;
}

.arrow-2:before {
  content: "";
  background: currentColor;
  width: 100%;
  clip-path: polygon(24% 19%,
      24% 39%,
      100% 40%,
      100% 54%,
      24% 55%,
      24% 76%,
      0% 50%);
}

/* change nav bar reactstrap */
.nav-link {
  color: black !important;
  /* background-color: #e3f3f9 !important; */
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
  padding: 8px 20px !important;
  font-family: Montserrat !important;
  font-weight: 500 !important;
  font-size: "16px" !important;
  border-color: #e1e1e1 #e1e1e1 transparent #e1e1e1 !important;
}

.nav-link.active {
  font-weight: bold !important;
  border-color: black black transparent black !important;
}

.nav-link.activeWhite {
  font-weight: bold !important;
  border-color: transparent !important;
}

/* reduce padding from mui select outlined */
.MuiSelect-outlined {
  padding: 8px 10px !important;
}

.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: -50px;
  left: -150px;
  min-width: 350px;
}

.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

/* .ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
} */

.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}

.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
  cursor: pointer;
}

.ol-popup-closer:after {
  content: "✖";
}

.underlineOnHover:hover {
  text-decoration: underline;
}

.MuiCheckbox-root {
  padding: 0px !important;
  color: #ffcc00 !important;
}

.bluecheckbox {
  padding: 0px !important;
  color: #1976d2 !important;
}

.arrow-run {
  margin-top: 7px;
  width: 45px;
  height: 23px;
  background: #374fe4;
  text-align: center;
  color: #fff;
  font-size: 13px;
  position: relative;
  display: table;
}

.arrow-run span {
  display: table-cell;
  vertical-align: middle;
}

.arrow-run:before,
.arrow-run:after {
  content: "";
  position: absolute;
}

.arrow-run:after {
  border-left: 20px solid #374fe4;
  border-right: 20px solid transparent;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  right: -40px;
  top: -8px;
  width: 0;
  height: 0;
  display: block;
}

/* NO check disableUnderline: true */
/* 
.MuiInput-root.hideBorderInputMui::before {
  border-bottom: 0px solid transparent !important;
} */

.datePickerMontserrat .MuiInputBase-input {
  font-family: Montserrat !important;
  font-weight: 500 !important;
}

.MuiTreeItem-label {
  font-family: Montserrat !important;
  font-weight: 500 !important;
}

.cesium-credit-logoContainer {
  display: none !important;
}

.cesium-credit-textContainer {
  display: none !important;
}

.draggable-box {
  resize: both;
  overflow: auto;
  height: 100%;
  max-width: 99%;
  max-height: 99%;
}

.show-cell {
  background: white;
  border-left: 1px solid lightgrey !important;
  border-right: 1px solid lightgrey !important;
  border-bottom: 1px solid lightgrey !important;
}

/* .show-name {
  font-weight: bold;
} */

.show-presenter {
  font-style: italic;
}

.cell-span {
  background-color: #00e5ff;
}

.ag-column-drop-wrapper {
  display: none !important;
}

.non-editable {
  background: lightgrey;
}

/* initial loading spinner */
.spinner {
  animation: spin infinite 2s linear;
}

/* mui stepper label */
.MuiStepLabel-labelContainer span {
  font-family: Montserrat;
}

/* react-dropzone div */
.dropzone {
  border: 2px dashed #e3e3e3;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.dropzone:hover {
  border-color: #4eacfe;
  background: rgba(76, 192, 243, 0.1);
}

.dropzone:active {
  border-color: #4eacfe;
  background: rgba(76, 192, 243, 0.1);
}