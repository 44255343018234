.splashscreen {
  width: 220px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 80px;
  margin-bottom: 80px;
}

.splashscreen h1:nth-child(1) {
  opacity: 0;
  font-family: "Work Sans", sans-serif;
  margin: 0 auto 10px auto;
  background: linear-gradient(to right, #1e48e2, #4f70e9, #4f70e9);
  font-weight: 800;
  font-size: 60px;
  width: 100%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow: hidden;
  animation: shring-animation 1.5s steps(40, end) 1.5s forwards,
    blink 0s step-end infinite alternate;
}
.splashscreen h1:nth-child(2) {
  width: 800px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  font-family: "Work Sans", sans-serif;
  font-weight: 800;
  font-size: 16px;
  overflow: hidden;
  animation: shring-animation2 1.5s steps(40, end) 1.5s forwards,
    blink 0s step-end infinite alternate;
}

@keyframes shring-animation {
  0% {
    opacity: 0;
    width: 0;
  }
  1% {
    opacity: 1;
    border-right: 1px solid white;
  }
  50% {
    opacity: 1;
    border-right: 1px solid white;
  }
  100% {
    opacity: 1;
    border-right: 1px solid white;
  }
}
@keyframes shring-animation2 {
  0% {
    opacity: 0;
    width: 0;
  }
  1% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.accedidiv,
.copyrightdiv {
  -webkit-animation: 5.5s ease 0s normal forwards 1 fadein;
  animation: 5.5s ease 0s normal forwards 1 fadein;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* squares and backgroud */
.context {
  width: 100%;
  position: absolute;
}

.context h1 {
  text-align: center;
  color: #fff;
  font-size: 50px;
}

.area {
  background: linear-gradient(110deg, #2b9c40, #caead0 100%),
    linear-gradient(80deg, #2b9c40, #caead0 100%),
    linear-gradient(0deg, #2b9c40, #caead0 100%);
  width: 100%;
  height: 100vh;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 3s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

.divLogin {
  font-family: "Open Sans", sans-serif;
}

.formFadeIn {
  -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 3s; /* Firefox < 16 */
  -ms-animation: fadein 3s; /* Internet Explorer */
  -o-animation: fadein 3s; /* Opera < 12.1 */
  animation: fadein 3s;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.example {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 24 !important;
}

.btnLogin {
  width: 400px;
  padding: 2px 10px 2px;
  margin: 10px auto;
  font-size: 13px;
  border-radius: 4px;
  border: 1px solid white;
  background-color: transparent;
  color: white;
}
.btnLogin:hover {
  background-color: white;
  color: black;
  transition: 1s;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
}
.login h1 {
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  letter-spacing: 1px;
  text-align: center;
}

.inputLogin {
  width: 400px;
  margin: 5px auto;
  background: #e8e8e8;
  border: none;
  outline: none;
  padding: 10px;
  font-size: 13px;
  color: black;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  -webkit-transition: box-shadow 0.5s ease;
  -moz-transition: box-shadow 0.5s ease;
  -o-transition: box-shadow 0.5s ease;
  -ms-transition: box-shadow 0.5s ease;
  transition: box-shadow 0.5s ease;
  z-index: 1 !important;
}

.introApp {
  position: fixed;
  bottom: 0;
  right: 0;
  padding-left: 20px;
  background-color: #000;
  font-family: sans-serif;
  text-transform: uppercase;
  font-size: 2em;
  letter-spacing: 4px;
  overflow: hidden;
  background: linear-gradient(90deg, #000, #fff, #000);
  background-repeat: no-repeat;
  background-size: 80%;
  animation: animate2 4s linear 1;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}

@keyframes animate2 {
  0% {
    background-position: -500%;
  }
  100% {
    background-position: 500%;
  }
}
